import React, { useEffect, useRef, useState } from 'react'
import FileManagerOperations from '../Services/FileManagerOperations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import FileUploader, { FileUploaderRef } from './FileUploader';
import { FileSession } from '../../../models/FileSession';

interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

export default function FileManagerNavigationMenu({ FileManagerOperations, FileSession }: Props) {
    const [menuType, setMenuType] = useState<string>('default');
    const fileUploaderRef = useRef<FileUploaderRef>(null);
    const [displayVersion, setDisplayVersion] = useState<string>('');
    const [highlightedItem, setHighlightedItem] = useState<string>('');
    const [clipboardLength, setClipboardLength] = useState<number>(0);

    // Function to determine the menu type based on selection
    const updateMenuType = () => {
        const selectedGridViewItems = FileManagerOperations.selectedGridViewItems;
        const selectedFileItem = FileManagerOperations.selectedFileItem;
        setDisplayVersion(FileManagerOperations.displayVersion);

        if (selectedGridViewItems?.length < 1) {
            setMenuType('gridFolder');
            return;
        }
        if (selectedFileItem) {
            if (selectedFileItem.isFile) {
                if (selectedGridViewItems.length > 1) {
                    setMenuType('multipleSelection');
                    return;
                }
                else {
                    setMenuType('file');
                    return;
                }
            }
            else {
                if (selectedGridViewItems.length > 1) {
                    setMenuType('multipleSelection');
                    return;
                }
                else {
                    setMenuType('gridFolder');
                    return;
                }
            }
        }
        else {
            if (selectedGridViewItems.length > 1) {
                setMenuType('multipleSelection');
                return;
            }
            else {
                setMenuType('gridFolder');
                return;
            }
        }
    };

    const updateDisplayVersion = () => {

        setDisplayVersion(FileManagerOperations.displayVersion);
        return;
    };

    const updateHighlightedItem = () => {
        if (FileManagerOperations.highlightedItem) {
            setHighlightedItem(FileManagerOperations.highlightedItem.name);

        }
    }

    const updateClipboardLenth = () => {
        setClipboardLength(FileManagerOperations?.clipboard?.length);
    }

    useEffect(() => {
        // Subscribe to selection change events in FileManagerOperations
        FileManagerOperations.on('treeViewSelectionChanged', updateMenuType);
        FileManagerOperations.on('gridViewSelectionChanged', updateMenuType);
        FileManagerOperations.on('fileItemSelected', updateMenuType);
        FileManagerOperations.on('displayVersionUpdated', updateDisplayVersion);
        FileManagerOperations.on('highlightedItemChanged', updateHighlightedItem);
        FileManagerOperations.on('clipboardUpdated', updateClipboardLenth);
        //highlightedItemChanged

        // Clean up event listeners when component unmounts
        return () => {
            FileManagerOperations.off('treeViewSelectionChanged', updateMenuType);
            FileManagerOperations.off('gridViewSelectionChanged', updateMenuType);
            FileManagerOperations.off('fileItemSelected', updateMenuType);
            FileManagerOperations.off('displayVersionUpdated', updateDisplayVersion);
            FileManagerOperations.off('highlightedItemChanged', updateHighlightedItem);
            FileManagerOperations.off('clipboardUpdated', updateClipboardLenth);
        };
    }, [FileManagerOperations]);



    const handleCreateFolder = () => {
        FileManagerOperations.setSelectDialog('createFolder');
        FileManagerOperations.setDialogOpen();
        //const folderName = prompt("Enter the new folder name:");
        //if (folderName) {
        //    FileManagerOperations.createFolder(folderName);
        //}
    };

    const handleUpload = async () => {
        fileUploaderRef.current?.triggerFileDialog();
    };

    const handleDelete = () => {
        FileManagerOperations.deleteSelectedItems();
    };

    const handleRename = () => {
        FileManagerOperations.setSelectDialog('rename');
        FileManagerOperations.setDialogOpen();
    };

    const handleCut = () => {
        FileManagerOperations.cutItems();
    };

    const handleCopy = () => {
        FileManagerOperations.copyItems();
    };

    const handlePaste = () => {
        FileManagerOperations.pasteItems();
    };

    const handleDownload = () => {
        FileManagerOperations.downloadSelectedItems();
    };

    const handleRefresh = async () => {
        await FileManagerOperations.refreshAllFiles();
        await FileManagerOperations.fetchTreeViewFolders("0");
        await FileManagerOperations.fetchGridViewItems();
    };

    const handleAttachments = async () => {
        FileManagerOperations.getAttachedItems();
        FileManagerOperations.setSelectDialog('attachments');
        FileManagerOperations.setDialogOpen();
    };

    const isActionDisabled = () => {
        return false;
        let selectedFileItem = FileManagerOperations?.selectedFileItem;
        if (selectedFileItem) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <div className="file-manager-menu">
            {/* Menu for TreeView folder or default */}
            {(menuType === 'default' || menuType === 'treeviewFolder') && (
                <>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-plus"
                        onClick={handleCreateFolder}
                        disabled={isActionDisabled()}
                    >
                        Create Folder
                    </ButtonComponent>

                    <ButtonComponent
                        iconCss="fa fa-upload"
                        className="default-button-style"
                        disabled={isActionDisabled()}
                        onClick={handleUpload}
                    >

                        Upload
                    </ButtonComponent>
                    {/* FileUploader Component */}
                    <FileUploader FileManagerOperations={FileManagerOperations} FileSession={FileSession} ref={fileUploaderRef}
                    />
                    <ButtonComponent
                        iconCss="fa fa-refresh"
                        className="default-button-style"
                        onClick={handleRefresh}
                        disabled={isActionDisabled()}
                    >
                        Refresh
                    </ButtonComponent>

                </>
            )}

            {/* Menu for GridView folder */}
            {menuType === 'gridFolder' && (
                <>
                    <ButtonComponent
                        iconCss="fa fa-plus"
                        className="default-button-style"
                        onClick={handleCreateFolder}
                        disabled={isActionDisabled()}
                    >
                        Create Folder
                    </ButtonComponent>
                    <ButtonComponent
                        iconCss="fa fa-upload"
                        className="default-button-style"
                        onClick={handleUpload}
                        disabled={isActionDisabled()}
                    >
                        Upload
                    </ButtonComponent>
                    {/* FileUploader Component */}
                    <FileUploader FileManagerOperations={FileManagerOperations} FileSession={FileSession} ref={fileUploaderRef} />
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-trash-o"
                        onClick={handleDelete}
                        disabled={isActionDisabled()}
                    >
                        Delete
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-edit"
                        onClick={handleRename}
                        disabled={isActionDisabled()}
                    >
                        Rename
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-files-o"
                        onClick={handleCopy}
                        disabled={isActionDisabled()}
                    >
                        Copy
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-scissors"
                        onClick={handleCut}
                        disabled={isActionDisabled()}
                    >
                        Cut
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-clipboard"
                        onClick={handlePaste}
                        disabled={FileManagerOperations.clipboard.length === 0}
                    >
                        Paste
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-download"
                        onClick={handleDownload}
                        disabled={isActionDisabled()}
                    >
                        Download
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-refresh"
                        onClick={handleRefresh}
                        disabled={isActionDisabled()}
                    >
                        Refresh
                    </ButtonComponent>

                    {
                        displayVersion === "select" &&
                        <ButtonComponent
                            className="default-button-style"
                            iconCss="fa fa-paperclip"
                            onClick={handleAttachments}
                            disabled={isActionDisabled()}
                        >
                            Attachments
                        </ButtonComponent>
                    }
                </>
            )}

            {/* Menu for File */}
            {menuType === 'file' && (
                <>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-trash-o"
                        onClick={handleDelete}
                        disabled={isActionDisabled()}
                    >
                        Delete
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-edit"
                        onClick={handleRename}
                        disabled={isActionDisabled()}
                    >
                        Rename
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-files-o"
                        onClick={handleCopy}
                        disabled={isActionDisabled()}
                    >
                        Copy
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-scissors"
                        onClick={handleCut}
                        disabled={isActionDisabled()}
                    >
                        Cut
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-download"
                        onClick={handleDownload}
                        disabled={isActionDisabled()}
                    >
                        Download
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-refresh"
                        onClick={handleRefresh}
                        disabled={isActionDisabled()}
                    >
                        Refresh
                    </ButtonComponent>
                    {
                        displayVersion === "select" &&
                        <ButtonComponent
                            className="default-button-style"
                            iconCss="fa fa-paperclip"
                            onClick={handleAttachments}
                            disabled={isActionDisabled()}
                        >
                            Attachments
                        </ButtonComponent>
                    }
                </>
            )}

            {/* Menu for Multiple Selection */}
            {menuType === 'multipleSelection' && (
                <>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-trash-o"
                        onClick={handleDelete}
                        disabled={isActionDisabled()}
                    >
                        Delete
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-files-o"
                        onClick={handleCopy}
                        disabled={isActionDisabled()}
                    >
                        Copy
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-scissors"
                        onClick={handleCut}
                        disabled={isActionDisabled()}
                    >
                        Cut
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-download"
                        onClick={handleDownload}
                        disabled={isActionDisabled()}
                    >
                        Download
                    </ButtonComponent>
                    <ButtonComponent
                        className="default-button-style"
                        iconCss="fa fa-refresh"
                        onClick={handleRefresh}
                        disabled={isActionDisabled()}
                    >
                        Refresh
                    </ButtonComponent>

                    {
                        displayVersion === "select" &&
                        <ButtonComponent
                            className="default-button-style"
                            iconCss="fa fa-paperclip"
                            onClick={handleAttachments}
                            disabled={isActionDisabled()}
                        >
                            Attachments
                        </ButtonComponent>
                    }
                </>
            )}

            {/* Fallback for undefined menuType */}
            {menuType === undefined && (
                <p className="info-message">No valid menu available. Please select an item.</p>
            )}
            {
                <>
                    {/* {highlightedItem}*/}
                </>

            }

        </div>

    );
}