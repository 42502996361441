import React from 'react';
import { ShowToastProps, toastRef, toastTypeConfig } from './ToastNotification';
import ReactDOMServer from 'react-dom/server';
import './ShowToast.scss';
// Exportable function to show toast

export const ShowToast = ({ header, message, type }: ShowToastProps) => {
    if (!toastRef.current) {
        console.error('Toast component is not mounted.');
        return;
    }
    const borderColorClass = (() => {
        console.log(type in toastTypeConfig, `Invalid toast type: ${type}`);
        switch (type) {
            case "Success":
                return 'toast-border-success'; // Green border
            case "Error":
                return 'toast-border-error'; // Red border
            case 'Warning':
                return 'toast-border-warning'; // Orange border
            case 'Information':
                return 'toast-border-info'; // Blue border
            default:
                return 'toast-border-info'; // Fallback
        }
    })();
    const { cssClass, icon } = toastTypeConfig[type];

    // Use JSX for building the template
    const Template = (

        <div className="e-toast-template" >
            <div className="e-toast-content" >
                <div className="e-toast-title">{header}</div>
                <div className="e-toast-message">{message}</div>
            </div>
        </div>
    );

    // Convert JSX to a string for Syncfusion's `ToastComponent`
    const templateString = ReactDOMServer.renderToStaticMarkup(Template);

    toastRef.current.show({
        content: templateString,
        cssClass: `${cssClass} ${borderColorClass}`,
        icon,
    });
};
