import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import { registerLicense } from '@syncfusion/ej2-base';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { msalConfig } from './authConfig';
import './custom-syncfusion.css';
import './index.css';
import './styles/index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZcdnRRRGhYVU11XUA=');


/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload
    ) {
        let account = event.payload as AccountInfo;
        msalInstance.setActiveAccount(account);
    }
});


root.render(
    <BrowserRouter>
        <App instance={msalInstance} />
    </BrowserRouter>
);
