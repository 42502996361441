import { PaneDirective, PanesDirective, SplitterComponent } from '@syncfusion/ej2-react-layouts'
import React, { useEffect } from 'react'
import FileManagerDataGrid from './FileManagerDataGrid'
import FileManagerTreeView from './FileManagerTreeView'
import FileManagerPropertiesPane from './FileManagerPropertiesPane'
import FileManagerOperations from '../Services/FileManagerOperations'
import { FileSession } from '../../../models/FileSession'
import FileManagerNavigationMenu from './FileManagerNavigationMenu'
import FileManagerStyledDialog from '../FileManagerStyledDialog'
import FileManagerDialogs from './FileManagerDialogs'
import '../BaseComponents/FileManagerLayout.scss'
import { useLocation } from 'react-router-dom'

interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

export default function FileManagerLayout({ FileManagerOperations, FileSession }: Props) {

    const treeViewContent = () => <FileManagerTreeView FileManagerOperations={FileManagerOperations} />;
    const dataGridContent = () => <FileManagerDataGrid FileManagerOperations={FileManagerOperations} FileSession={FileSession} />;
    const propertiesPaneContent = () => <FileManagerPropertiesPane FileManagerOperations={FileManagerOperations} FileSession={FileSession} />;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const displayVersion = queryParams.get('display') ?? "";
    const attachedModel = queryParams.get('UniqueSourceId') ?? "";

    FileManagerOperations.setDisplayVersion(displayVersion);
    FileManagerOperations.setAttachedModel(attachedModel);
    const handleResizing = (e: any) => {
        FileManagerOperations.setResizeToken();
    };
    return (
        <div className="file-manager-layout" id="content-app">

            {/* Top Menu */}
            <FileManagerNavigationMenu FileManagerOperations={FileManagerOperations} FileSession={ FileSession} />
            {/* File Manager Content */}
            <SplitterComponent height="90vh" width="100%" orientation="Horizontal" separatorSize={4} resizing={handleResizing} >
                <PanesDirective>
                    {/* TreeView Pane */}
                    <PaneDirective size="20%" min="15%" content={treeViewContent} />

                    {/* Data Grid Pane */}
                    <PaneDirective size="55%" min="50%" content={dataGridContent} />

                    {/* Properties/Preview Pane */}
                    <PaneDirective collapsible={true} size="25%" min="2px" content={propertiesPaneContent} />
                </PanesDirective>
            </SplitterComponent>

            {/* Dialog Types */}
            <FileManagerDialogs FileManagerOperations={FileManagerOperations} FileSession={FileSession} />
        </div>
    );
}
