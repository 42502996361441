export class FolderItemsRequest {
    id: string;
    page: number;
    pageSize: number;
    sortColumn: string;
    sortDirection: string;
    searchQuery: string;
    includeHidden: boolean;

    constructor(
        id: string,
        page: number = 1,
        pageSize: number = 50,
        sortColumn: string = '',
        sortDirection: string = 'asc',
        searchQuery: string = '',
        includeHidden: boolean = false
    ) {
        this.id = id;
        this.page = page;
        this.pageSize = pageSize;
        this.sortColumn = sortColumn;
        this.sortDirection = sortDirection;
        this.searchQuery = searchQuery;
        this.includeHidden = includeHidden;
    }
}
