import React, { useRef } from 'react';
import {
    ToastComponent,
    ToastModel,
    ToastBeforeOpenArgs,
    ToastAnimationSettingsModel,
} from '@syncfusion/ej2-react-notifications';

import { PositionDataModel } from '@syncfusion/ej2-popups';
type ToastType = 'Success' | 'Error' | 'Warning' | 'Information';

export interface ShowToastProps {
    header: string;
    message: string;
    type: ToastType;
}

interface ToastNotificationProps {
    position?: PositionDataModel;
    animation?: ToastModel['animation'];
}

export const toastTypeConfig: Record<ToastType, { cssClass: string; icon: string }> = {
    Success: { cssClass: 'e-toast-success', icon: 'e-success toast-icons' },
    Error: { cssClass: 'e-toast-danger', icon: 'e-error toast-icons' },
    Warning: { cssClass: 'e-toast-warning', icon: 'e-warning toast-icons' },
    Information: { cssClass: 'e-toast-info', icon: 'e-info toast-icons' },
};

export let toastRef: React.RefObject<ToastComponent>;

const ToastNotification: React.FC<ToastNotificationProps> = ({
    position = { X: 'Right', Y: 'Top' },
    animation = {
        hide: { effect: 'SlideRightOut' },
        show: { effect: 'SlideRightIn' },
    },
}) => {
    toastRef = useRef<ToastComponent>(null);

    return (
        <div>
            <ToastComponent
                ref={toastRef}
                id="toast_notification"
                position={position}
                animation={animation}
                showCloseButton={true}
                timeOut={5000}
            />
        </div>
    );
};

export default ToastNotification;